// Core
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { Button, Modal } from 'antd';

const Excerpt = (props) => {
  const { onClose, open, context } = props;

  useEffect(() => {
    console.log('ssssssssssscontext', context);
  }, [context]);

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Modal
        title="Response Sources"
        visible={open}
        onCancel={handleClose}
        footer={[
          <Button key="close" onClick={onClose} type="primary">
            Close
          </Button>,
        ]}
        className='__common_model_class'
      >
        <div className='__common_model_content'>
          <div className='____common_model_class_desc'>
            {context?.slice(0, 5).map((item, index) => {
              return (
                <div className='__single_context' key={index}>
                  <h1>Section {index + 1}</h1>
                  <p>{item?.page_content}</p>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Excerpt;

Excerpt.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  context: PropTypes.any,
};
