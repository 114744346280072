// Core
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// logo
import LogoFull from 'assets/images/logo-EV-4-dark green.png'
import AiButton from 'assets/images/AiButton.png'

// Styles
import './styles.scss';
import 'react-photo-view/dist/react-photo-view.css';

import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { APP_ID } from 'config/constants';

import { UserOutlined, GlobalOutlined, MenuOutlined, SearchOutlined } from '@ant-design/icons';
import { Drawer, Avatar, Radio, Space } from 'antd';


import { DropdownItem, DropdownTrigger, Dropdown, DropdownMenu, Popover, PopoverTrigger, PopoverContent } from "@nextui-org/react";
import { ChevronDown, Lock, Activity, Flash, Server, TagUser, Scale } from "./Icons";
import Login from 'components/modals/Login';
import Button from '../Button';
import InputField from '../InputField';
import AskModal from 'components/modals/AskModal';


const Header = () => {
  const { userDetails, currentLang } = useSelector((store) => store.commonData);
  const localDetails = localStorage.getItem('userDetails');
  const { i18n } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [header, setHeaderClass] = useState("__header");
  const [openLogin, setOpenLogin] = useState(false);
  const [currLng, setCurrLng] = useState('ENG')
  const [show, setShow] = useState(false);
  const [openLogOut, setLogOutOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState('')

  const [searchInValue, setSearchInValue] = useState(1);
  const [searchForValue, setSearchForValue] = useState(1);

  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState('');
  const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, severity: '' })
  const { searchkey } = data;

  const [openPopOver, setOpenPopOver] = useState(false);

  const handleOnChange = (key) => (value) => {
    setError({
      ...error,
      [key]: null
    });
    setData({
      ...data,
      [key]: value
    });
  };

  const onChangeSearchIn = (e) => {
    console.log("e", e);
    setSearchInValue(e.target.value);
  };

  const getLabelText = (value) => {
    switch (value) {
      case 1:
        return 'Articles';
      case 2:
        return 'Alwaraq';
      case 3:
        return 'Ibn Battuta';
      case 4:
        return 'Science Museum';
      case 5:
        return 'Cinemapedia';
      case 7:
        return 'Uae History';
      case 6:
        return 'Other Projects';
      default:
        return '';
    }
  }

  const onChangeSearchFor = (e) => {
    setSearchForValue(e.target.value);
  };

  const handleSubmit = () => {
    const errors = {
      searchkey: !searchkey && 'Enter your search word',
    };
    setError(errors);
    let isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    setOpenPopOver(false)
    navigate(`/search?searchText=${searchkey}&searchFor=${searchForValue}&searchIn=${searchInValue}&name=${getLabelText(searchInValue)}`)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const listenScrollEvent = event => {
    if (window.scrollY < 50) {
      return setHeaderClass("nav-background");
    } else if (window.scrollY > 45) {
      return setHeaderClass("nav-background is-scrolled");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  const handleCloseLogin = () => {
    setOpenLogin(false)
  }

  const handleChangeLang = async (value) => {
    setCurrLng(value === 'ar' ? 'عربي' : 'ENG')
    i18n.changeLanguage(value);
    dispatch({
      type: 'currentLang',
      value: { lang: value, id: value === 'ar' ? 1 : 2 }
    });
    if (value === 'ar') {
      document.body.classList.add('arabic');
    }
    else {
      document.body.classList.remove('arabic')
    }
    const localDetails = localStorage.getItem('userDetails');
    if (localDetails) {
      const formData = new FormData();
      formData.append('language', value);
      formData.append('appId', APP_ID);
      const res = await fetchData(restAPIs.changeLanguage(formData));
      if (res.statusCode === 200) {
        dispatch({
          type: 'userDetails',
          value: { ...userDetails, language: res.language }
        });
      }
    }
  };


  const checkSubsription = async () => {
    const formD = new FormData();
    formD.append('appId', APP_ID)
    const res = await fetchData(restAPIs.subscriptionForm(formD));
    dispatch({
      type: 'userDetails',
      value: { ...res, language: res.language == "1" ? 'ar' : 'en' }
    });
    localStorage.setItem('userDetails', JSON.stringify(res));
    localStorage.setItem('sessiontoken', res.sessionToken);
    i18n.changeLanguage(res.language == "1" ? 'ar' : 'en');
    dispatch({
      type: 'currentLang',
      value: { lang: res?.language ? (res.language == "1" ? 'ar' : 'en') : 'en', id: res?.language ? +res.language : 2 }
    });

    if (res.language == "1") {
      document.body.classList.add('arabic');
    }
    else {
      document.body.classList.remove('arabic')
    }

  }

  useEffect(() => {
    const localDetails = localStorage.getItem('userDetails');
    if (currentLang.lang === 'ar') {
      document.body.classList.add('arabic');
    }
    else {
      document.body.classList.remove('arabic')
    }

    if (localDetails?.userid != "") {
      checkSubsription();
    }
    else {
      i18n.changeLanguage(currentLang.lang);
    }
  }, []);

  const openUnsubscribe = () => {
    setShow(true);
  };

  const closeUnsubscribe = (value) => {
    setShow(false);
  };

  const gotoHome = () => {
    navigate('home')
  }


  const icons = {
    chevron: <ChevronDown fill="currentColor" size={16} />,
    scale: <Scale className="text-warning" fill="currentColor" size={30} />,
    lock: <Lock className="text-success" fill="currentColor" size={30} />,
    activity: <Activity className="text-secondary" fill="currentColor" size={30} />,
    flash: <Flash className="text-primary" fill="currentColor" size={30} />,
    server: <Server className="text-success" fill="currentColor" size={30} />,
    user: <TagUser className="text-danger" fill="currentColor" size={30} />,
  };

  const handleNavigateAbout = (to, id) => {
    if (to === 'about')
      navigate('about')
    else
      navigate('founder#' + id)
  }


  const aboutIcons = {
    company: <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M64 48c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16h80V400c0-26.5 21.5-48 48-48s48 21.5 48 48v64h80c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64zM0 64C0 28.7 28.7 0 64 0H320c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm88 40c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H104c-8.8 0-16-7.2-16-16V104zM232 88h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H232c-8.8 0-16-7.2-16-16V104c0-8.8 7.2-16 16-16zM88 232c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H104c-8.8 0-16-7.2-16-16V232zm144-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H232c-8.8 0-16-7.2-16-16V232c0-8.8 7.2-16 16-16z" /></svg>,
    inception: <svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512"><path opacity="1" fill="#ee8bb8" d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V299.6l-94.7 94.7c-8.2 8.2-14 18.5-16.8 29.7l-15 60.1c-2.3 9.4-1.8 19 1.4 27.8H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z" /></svg>,
    founder: <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path opacity="1" fill="#7869E6" d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" /></svg>,
    founderPoetry: <><svg xmlns="http://www.w3.org/2000/svg" height="16" width="20" viewBox="0 0 640 512"><path fill="#70C9F4" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H322.8c-3.1-8.8-3.7-18.4-1.4-27.8l15-60.1c2.8-11.3 8.6-21.5 16.8-29.7l40.3-40.3c-32.1-31-75.7-50.1-123.9-50.1H178.3zm435.5-68.3c-15.6-15.6-40.9-15.6-56.6 0l-29.4 29.4 71 71 29.4-29.4c15.6-15.6 15.6-40.9 0-56.6l-14.4-14.4zM375.9 417c-4.1 4.1-7 9.2-8.4 14.9l-15 60.1c-1.4 5.5 .2 11.2 4.2 15.2s9.7 5.6 15.2 4.2l60.1-15c5.6-1.4 10.8-4.3 14.9-8.4L576.1 358.7l-71-71L375.9 417z" /></svg></>,
    poetry: <><svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path opacity="1" fill="#52B588" d="M368.4 18.3L312.7 74.1 437.9 199.3l55.7-55.7c21.9-21.9 21.9-57.3 0-79.2L447.6 18.3c-21.9-21.9-57.3-21.9-79.2 0zM288 94.6l-9.2 2.8L134.7 140.6c-19.9 6-35.7 21.2-42.3 41L3.8 445.8c-3.8 11.3-1 23.9 7.3 32.4L164.7 324.7c-3-6.3-4.7-13.3-4.7-20.7c0-26.5 21.5-48 48-48s48 21.5 48 48s-21.5 48-48 48c-7.4 0-14.4-1.7-20.7-4.7L33.7 500.9c8.6 8.3 21.1 11.2 32.4 7.3l264.3-88.6c19.7-6.6 35-22.4 41-42.3l43.2-144.1 2.8-9.2L288 94.6z" /></svg></>,
    soundRecording: <><svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path opacity="1" fill="#E2944C" d="M96 96V256c0 53 43 96 96 96s96-43 96-96H208c-8.8 0-16-7.2-16-16s7.2-16 16-16h80V192H208c-8.8 0-16-7.2-16-16s7.2-16 16-16h80V128H208c-8.8 0-16-7.2-16-16s7.2-16 16-16h80c0-53-43-96-96-96S96 43 96 96zM320 240v16c0 70.7-57.3 128-128 128s-128-57.3-128-128V216c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 89.1 66.2 162.7 152 174.4V464H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h72 72c13.3 0 24-10.7 24-24s-10.7-24-24-24H216V430.4c85.8-11.7 152-85.3 152-174.4V216c0-13.3-10.7-24-24-24s-24 10.7-24 24v24z" /></svg></>,
    documentaryFilms: <><svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path opacity="1" fill="#F6CA45" d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM48 368v32c0 8.8 7.2 16 16 16H96c8.8 0 16-7.2 16-16V368c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16zm368-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V368c0-8.8-7.2-16-16-16H416zM48 240v32c0 8.8 7.2 16 16 16H96c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16zm368-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16H416zM48 112v32c0 8.8 7.2 16 16 16H96c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16zM416 96c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H416zM160 128v64c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32V128c0-17.7-14.3-32-32-32H192c-17.7 0-32 14.3-32 32zm32 160c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32V320c0-17.7-14.3-32-32-32H192z" /></svg></>,
    achivements: <><svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512"><path opacity="1" fill="#5677FF" d="M400 0H176c-26.5 0-48.1 21.8-47.1 48.2c.2 5.3 .4 10.6 .7 15.8H24C10.7 64 0 74.7 0 88c0 92.6 33.5 157 78.5 200.7c44.3 43.1 98.3 64.8 138.1 75.8c23.4 6.5 39.4 26 39.4 45.6c0 20.9-17 37.9-37.9 37.9H192c-17.7 0-32 14.3-32 32s14.3 32 32 32H384c17.7 0 32-14.3 32-32s-14.3-32-32-32H357.9C337 448 320 431 320 410.1c0-19.6 15.9-39.2 39.4-45.6c39.9-11 93.9-32.7 138.2-75.8C542.5 245 576 180.6 576 88c0-13.3-10.7-24-24-24H446.4c.3-5.2 .5-10.4 .7-15.8C448.1 21.8 426.5 0 400 0zM48.9 112h84.4c9.1 90.1 29.2 150.3 51.9 190.6c-24.9-11-50.8-26.5-73.2-48.3c-32-31.1-58-76-63-142.3zM464.1 254.3c-22.4 21.8-48.3 37.3-73.2 48.3c22.7-40.3 42.8-100.5 51.9-190.6h84.4c-5.1 66.3-31.1 111.2-63 142.3z" /></svg></>,
    awards: <><svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path fill="#3ECAF9" d="M4.1 38.2C1.4 34.2 0 29.4 0 24.6C0 11 11 0 24.6 0H133.9c11.2 0 21.7 5.9 27.4 15.5l68.5 114.1c-48.2 6.1-91.3 28.6-123.4 61.9L4.1 38.2zm503.7 0L405.6 191.5c-32.1-33.3-75.2-55.8-123.4-61.9L350.7 15.5C356.5 5.9 366.9 0 378.1 0H487.4C501 0 512 11 512 24.6c0 4.8-1.4 9.6-4.1 13.6zM80 336a176 176 0 1 1 352 0A176 176 0 1 1 80 336zm184.4-94.9c-3.4-7-13.3-7-16.8 0l-22.4 45.4c-1.4 2.8-4 4.7-7 5.1L168 298.9c-7.7 1.1-10.7 10.5-5.2 16l36.3 35.4c2.2 2.2 3.2 5.2 2.7 8.3l-8.6 49.9c-1.3 7.6 6.7 13.5 13.6 9.9l44.8-23.6c2.7-1.4 6-1.4 8.7 0l44.8 23.6c6.9 3.6 14.9-2.2 13.6-9.9l-8.6-49.9c-.5-3 .5-6.1 2.7-8.3l36.3-35.4c5.6-5.4 2.5-14.8-5.2-16l-50.1-7.3c-3-.4-5.7-2.4-7-5.1l-22.4-45.4z" /></svg></>,
    teams: <><svg xmlns="http://www.w3.org/2000/svg" height="16" width="20" viewBox="0 0 640 512"><path opacity="1" fill="#d977d9" d="M72 88a56 56 0 1 1 112 0A56 56 0 1 1 72 88zM64 245.7C54 256.9 48 271.8 48 288s6 31.1 16 42.3V245.7zm144.4-49.3C178.7 222.7 160 261.2 160 304c0 34.3 12 65.8 32 90.5V416c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V389.2C26.2 371.2 0 332.7 0 288c0-61.9 50.1-112 112-112h32c24 0 46.2 7.5 64.4 20.3zM448 416V394.5c20-24.7 32-56.2 32-90.5c0-42.8-18.7-81.3-48.4-107.7C449.8 183.5 472 176 496 176h32c61.9 0 112 50.1 112 112c0 44.7-26.2 83.2-64 101.2V416c0 17.7-14.3 32-32 32H480c-17.7 0-32-14.3-32-32zm8-328a56 56 0 1 1 112 0A56 56 0 1 1 456 88zM576 245.7v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM320 32a64 64 0 1 1 0 128 64 64 0 1 1 0-128zM240 304c0 16.2 6 31 16 42.3V261.7c-10 11.3-16 26.1-16 42.3zm144-42.3v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM448 304c0 44.7-26.2 83.2-64 101.2V448c0 17.7-14.3 32-32 32H288c-17.7 0-32-14.3-32-32V405.2c-37.8-18-64-56.5-64-101.2c0-61.9 50.1-112 112-112h32c61.9 0 112 50.1 112 112z" /></svg></>,
  }

  const handleNavigateArticle = (to) => {
    navigate(to)
  }


  const menuItems = [
    {
      key: 'home',
      // icon: <HomeOutlined />,
      label: t('home')
    },
    {
      key: 'projects',
      // icon: <ProjectOutlined />,
      label: t('projects'),
    },
    {
      key: 'empty',
      // icon: <CopyOutlined />,
      label: t('articles'),
      children: [
        {
          key: 'he-articles',
          label: 'H.E Articles',
        },
        {
          key: 'application-articles',
          label: 'Application Articles'
        },
        {
          key: 'general-articles',
          label: 'General Articles'
        }
      ]
    },
    {
      key: 'competitions',
      // icon: <Activity className="text-secondary" fill="currentColor" size={30} />,
      label: t('competitions'),
    },
    {
      key: 'news',
      // icon: <Activity className="text-secondary" fill="currentColor" size={30} />,
      label: t('news'),
    },
    {
      key: 'awards',
      // icon: <Activity className="text-secondary" fill="currentColor" size={30} />,
      label: t('ib_awards'),
    },
  ]


  const onDrawerNavigateArticle = ({ key }) => {
    onClose();
    navigate('/' + key)
  };

  const handleLogout = () => {
    dispatch({
      type: 'userDetails',
      value: null
    });
    localStorage.setItem('userDetails', '');
    localStorage.setItem('token', '');
    if (location?.pathname.slice(1) == 'settings')
      navigate('/');
  };

  const handleClickOpen = () => {
    setShow(true);
  };

  const handleCloseModel = (value) => {
    setShow(false);
  };

  useEffect(() => {
    let path = location?.pathname.slice(1)
    setCurrentPath(path)
  }, [])

  const [isVertical, setIsVertical] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsVertical(window.innerWidth < 768); // Adjust the breakpoint as per your requirement
    };
    // Initial check on component mount
    handleResize();
    // Event listener for window resize
    window.addEventListener('resize', handleResize);
    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const [openAskModal, setOpenAskModal] = useState(false);

  const handleOpenAskModal = () => {
    setOpenAskModal(true);
  };

  return (
    <>
      <header>
        <div className='container-fluid'>
          <div className={header}></div>
          <div className='__top_menu'>

            <a className='__logo' >
              <button className='__menu_outlined' onClick={showDrawer} > <MenuOutlined /> </button>
              <img src={LogoFull} alt="" onClick={() => gotoHome()} />
            </a>
            <div className='__center_menu'>
              <Dropdown
                classNames={{
                  backdrop: "bg-default-200222",
                  base: '___border__o'
                }}

                variant={'solid'}
                backdrop="opaque"

              >
                <DropdownTrigger>
                  <a> {t('about_us')} {icons.chevron}  </a>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="About"
                  className="w-[340px] ____zero_pd"
                  itemClasses={{
                    base: "gap-4 _________________________abc",
                  }}
                >

                  <DropdownItem>
                    <div className='__drop_down_content'>
                      <h4>{t('about_us')}</h4>
                      <div className='__dropdown_items_list' >
                        <div className='__dropdown_single __hover_1' onClick={() => handleNavigateAbout('about', 0)}>
                          <div style={{ color: '', fontSize: '21px' }}>{aboutIcons?.company}</div>
                          <div>
                            <h5>{t('company')}</h5>
                            <p>{t('electronicVillageIsACulturalOrganizationBasedInUAE')}</p>
                          </div>
                        </div>

                        <div className='__dropdown_single __hover_2' onClick={() => handleNavigateAbout('founder', 0)}>
                          <div style={{ color: '#EE8BB8', fontSize: '21px' }}>
                            {aboutIcons?.founder}
                          </div>
                          <div>
                            <h5>{t('founder_n')}</h5>
                            <p>{t('hisExcellencyMohammedAhmedKhalifaAlSuwaidi')}</p>
                          </div>
                        </div>
                        <div className='__dropdown_single __hover_3' onClick={() => handleNavigateAbout('founder', 5)}>
                          <div style={{ color: '#70C9F4', fontSize: '21px' }} >{aboutIcons?.founderPoetry}</div>
                          <div>
                            <h5>{t('foundersPoetry')}</h5>
                            <p>{t('poemsAndSongs')}</p>
                          </div>
                        </div>

                        <div className='__dropdown_single __hover_4' onClick={() => handleNavigateAbout('founder', 4)}>
                          <div style={{ color: '#52B588', fontSize: '21px' }} >
                            {aboutIcons?.poetry}
                          </div>
                          <div>
                            <h5>{t('poeticAndLiterary')} </h5>
                            <p>{t('collectionsOfWorks')}</p>
                          </div>
                        </div>

                        <div className='__dropdown_single __hover_5' onClick={() => handleNavigateAbout('founder', 5)}>
                          <div style={{ color: '#E2944C', fontSize: '21px' }} >
                            {aboutIcons?.soundRecording}
                          </div>
                          <div>
                            <h5>{t('soundRecordings')}</h5>
                            <p>{t('albumsAndOtherWorks')}</p>
                          </div>
                        </div>


                        <div className='__dropdown_single __hover_6' onClick={() => handleNavigateAbout('founder', 7)}>
                          <div style={{ color: '#F6CA45', fontSize: '21px' }}>
                            {aboutIcons?.documentaryFilms}
                          </div>
                          <div>
                          <h5>{t('documentaryFilms')}</h5>
                            <p>{t('worksInTheFilmField')}</p>
                          </div>
                        </div>

                        <div className='__dropdown_single __hover_7' onClick={() => handleNavigateAbout('founder', 8)}>
                          <div style={{ color: '#5677FF', fontSize: '21px' }}>
                            {aboutIcons?.achivements}
                          </div>
                          <div>
                          <h5>{t('achievements')}</h5>
                            <p>{t('outcomesInTheCulturalField')}</p>
                          </div>
                        </div>

                        <div className='__dropdown_single __hover_8' onClick={() => handleNavigateAbout('founder', 9)}>
                          <div style={{ color: '#3ECAF9', fontSize: '21px' }}>
                            {aboutIcons?.awards}
                          </div>
                          <div>
                          <h5>{t('awardsReceived')}</h5>
                            <p>{t('recognitionsEarnedByTheFounder')}</p>
                          </div>
                        </div>
                        <div className='__dropdown_single __hover_9' onClick={() => handleNavigateAbout('about', 0)}>
                          <div style={{ color: '#d977d9', fontSize: '21px' }}>
                            {aboutIcons?.teams}
                          </div>
                          <div>
                            <h5>{t('teams')}</h5>
                            <p>{t('aSymphonyOfDedicatedDepartments')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              {/* <NavLink to={'/articles'} end>{t('articles')}</NavLink> */}
              <NavLink to={'/projects'} end>{t('projects')}</NavLink>

              <Dropdown
                classNames={{
                  backdrop: "bg-default-200222",
                  base: '___border__o'
                }}

                variant={'solid'}
                backdrop="opaque"

              >
                <DropdownTrigger>
                  <a> {t('articles')} {icons.chevron}  </a>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="About"
                  className="w-[340px] ____zero_pd"
                  itemClasses={{
                    base: "gap-4 _________________________abc",
                  }}
                >

                  <DropdownItem>
                    <div className='__drop_down_content'>
                      <h4>{t('articles')}</h4>
                      <div className='__dropdown_items_list' >
                        <div className='__dropdown_single __hover_1' onClick={() => handleNavigateArticle('he-articles')}>
                          <div style={{ color: '', fontSize: '21px' }}>{aboutIcons?.company}</div>
                          <div>
                          <h5>{t('heArticles')}</h5>
                            <p>{t('articlesOfHisExcellencyMohammedAlSuwaidi')}</p>
                          </div>
                        </div>

                        <div className='__dropdown_single __hover_2' onClick={() => handleNavigateArticle('application-articles')}>
                          <div style={{ color: '#EE8BB8', fontSize: '21px' }}>
                            {aboutIcons?.founder}
                          </div>
                          <div>
                          <h5>{t('applicationArticles')}</h5>
                            <p>{t('articlesOfApplicationsAndProjects')}</p>
                          </div>
                        </div>

                        <div className='__dropdown_single __hover_3' onClick={() => handleNavigateArticle('general-articles')}>
                          <div style={{ color: '#70C9F4', fontSize: '21px' }} >{aboutIcons?.founderPoetry}</div>
                          <div>
                          <h5>{t('generalArticles')}</h5>
                            <p>{t('generalArticles')}</p>
                          </div>
                        </div>



                      </div>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>



              <NavLink to={'/competitions'} end> {t('competitions')}</NavLink>
              <NavLink to={'/news'} end>{t('news')}</NavLink>

            </div>
            <div className='__right_menu'>
            <button className='__ask' onClick={() => handleOpenAskModal()}><img src={AiButton} /></button>

              <Popover placement={'bottom-end'} isOpen={openPopOver} onOpenChange={(open) => setOpenPopOver(open)}>
                <PopoverTrigger style={{ outline: 'none' }}>
                  <SearchOutlined style={{ outline: 'none' }} />
                </PopoverTrigger>
                <PopoverContent>
                  <div className='__search_box'>
                    <div className='__input_sec'>
                      <InputField
                        type="text"
                        placeholder={t('search')}
                        value={searchkey}
                        onChange={handleOnChange('searchkey')}
                        error={error.searchkey}
                        onKeyDown={handleKeyDown}
                      />
                      <Button
                        icon={<SearchOutlined />}
                        handleOnClick={handleSubmit}
                        withLoader
                        loading={isLoading}
                        disabled={isLoading}

                      />
                    </div>

                    <div className='__filter_sec'>
                      <div className='__single'>
                        <h5>Search In</h5>
                        <Radio.Group onChange={onChangeSearchIn} value={searchInValue}>
                          <Space direction={isVertical ? 'vertical' : 'horizontal'} style={{alignItems:'flex-start'}}>
                            <Space direction="vertical">
                              <Radio value={1} name='Articles'>Articles</Radio>
                              <Radio value={2} name='Alwaraq'>Alwaraq</Radio>
                              <Radio value={3} name='Ibn Battuta'>Ibn Battuta</Radio>
                              <Radio value={4} name='Science Museum'>Science Museum</Radio>
                            </Space>
                            <Space direction="vertical" >
                              <Radio value={5} name='Cinemapedia'>Cinemapedia</Radio>
                              <Radio value={7} name='Uae History'> Uae History</Radio>
                              <Radio value={6} name='Other Projects'>Other Projects</Radio>
                            </Space>
                          </Space>
                        </Radio.Group>
                      </div>

                      <div className='__single'>
                        <h5>Search For</h5>
                        <Radio.Group onChange={onChangeSearchFor} value={searchForValue}>
                          <Space direction="vertical">
                            <Radio value={1}>Any</Radio>
                            <Radio value={2}>Exact Phrase</Radio>
                          </Space>
                        </Radio.Group>
                      </div>
                    </div>

                  </div>
                </PopoverContent>
              </Popover>

              <Dropdown
                classNames={{
                  backdrop: "bg-default-200222",
                  base: '___border__o'
                }}

                variant={'solid'}
                backdrop="opaque"

              >
                <DropdownTrigger>
                  <span style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>{currLng} <GlobalOutlined style={{ paddingBottom: '5px' }} /></span>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Language"
                  className="____zero_pd"
                  itemClasses={{
                    base: "gap-4 _________________________abc",
                  }}
                >

                  <DropdownItem>
                    <div className='__lng_drops'>
                      <a onClick={() => handleChangeLang('ar')} className={currLng === 'عربي' ? '__activ_lng' : ''}>عربي (Arabic)</a>
                      <a onClick={() => handleChangeLang('en')} className={currLng === 'ENG' ? '__activ_lng' : ''}>ENG (English)</a>
                    </div>

                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              {userDetails?.fullname ? <NavLink to={'settings'} end style={{ color: '#fff', textDecoration: 'none' }}><Avatar src={<img src={userDetails?.profile_pic} alt={userDetails?.fullname} />} /></NavLink> : <a className='__prof-btn' onClick={() => setOpenLogin(true)}><UserOutlined /></a>}
              <Login open={openLogin} onClose={() => handleCloseLogin()} />
            </div>

          </div>
        </div>
      </header>
      <Drawer
        title={
          <a className='__logo'>
            <img src={LogoFull} style={{ height: '65px' }} />
          </a>
        }
        placement={currentLang?.id === 1 ? "right" : "left"}
        onClose={onClose}
        open={open}
        width={300}
        closeIcon={false}
        className='__drawer_menu'
      >

        <p><NavLink to={'/home'} end onClick={onClose}> {t('home')}</NavLink></p>
        <p><NavLink to={'/projects'} end onClick={onClose}>{t('projects')}</NavLink></p>
        <p><NavLink to={'/he-articles'} end onClick={onClose}> {t('heArticles')}</NavLink></p>
        <p><NavLink to={'/application-articles'} end onClick={onClose}> {t('applicationArticles')}</NavLink></p>
        <p><NavLink to={'/general-articles'} end onClick={onClose}> {t('generalArticles')}</NavLink></p>
        <p><NavLink to={'/awards'} end onClick={onClose}>{t('ib_awards')}</NavLink></p>
        <p><NavLink to={'/competitions'} end onClick={onClose}> {t('competitions')}</NavLink></p>
        <p><NavLink to={'/news'} end onClick={onClose}>{t('news')}</NavLink></p>
        <p><NavLink to={'/gallery'} end onClick={onClose}>Gallery</NavLink></p>


        {/* <Menu
          theme={'light'}
          mode="inline"
          onClick={onDrawerNavigateArticle}
          items={menuItems}
          selectedKeys={[currentPath]}
          defaultSelectedKeys={[currentPath]}
        /> */}

        <div className='border-bottom mt-3 mb-4' ></div>
        <p className='__so'><NavLink to={'/about'} end onClick={onClose}>{t('about_us')}</NavLink></p>
        <p className='__so'><NavLink to={'/contact'} end onClick={onClose}>{t('contact_us')}</NavLink></p>
        <p className='__so'><NavLink to={'/cookie'} end onClick={onClose}>{t('cookies_policy')}</NavLink></p>
        <p className='__so'><NavLink to={'/terms'} end onClick={onClose}>{t('terms_and_conditions')}</NavLink></p>
        <p className='__so'><NavLink to={'/privacy'} end onClick={onClose}>{t('privacy_policy')}</NavLink></p>
        <div className='border-bottom mt-3 mb-4' ></div>
        <div >
          {
            userDetails?.fullname ?
              <>
                <p><NavLink to={'/settings'} end onClick={onClose}>{t('settings')}</NavLink></p>
                <p><a onClick={handleLogout}>{t('sign_out')}</a></p>
              </>
              :
              <p><a onClick={handleClickOpen}>{t('login')}</a></p>
          }
        </div>
      </Drawer>
      <Login
        open={show}
        onClose={handleCloseModel}
      />

        <AskModal
          open={openAskModal}
          onClose={() => setOpenAskModal(false)} 
        />

    </>
  );
};

export default Header;
